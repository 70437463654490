import React, { useState, useEffect } from 'react';
//import ConvaiClientSetup from './ConvaiClientSetup';
import { useConvaiClient } from './hooks/useConvaiClient';
//import Chat from './Chat';
import ThreeJsAvatar from './ThreeJsAvatar';
import ChatBubble from './components/chat/Chat'; // Import ChatBubble
import './SessionPage.css';
import { useParams, useLocation } from 'react-router-dom';
import io from 'socket.io-client';
import ReactMarkdown from 'react-markdown';

const socket = io('https://proto1-production.up.railway.app');

const SessionPage = () => {
  const { sessionId } = useParams();
  const location = useLocation();
  const [userText, setUserText] = useState('');
  const [npcText, setNpcText] = useState('');
  const [isTalking, setIsTalking] = useState(false);
  const [documentText, setDocumentText] = useState('');
  const [sessionDetails, setSessionDetails] = useState({ name: '', goal: '' });
  const [messages, setMessages] = useState([]);

  const fetchDocument = async () => {
    try {
      const userId = localStorage.getItem('userId');
      const response = await fetch(`https://proto1-production.up.railway.app/api/realtime-doc/${sessionId}`);

      if (!response.ok) {
        throw new Error('Error fetching document');
      }

      const data = await response.json();
      setDocumentText(data.LLMResponse);

      // Use a callback for the chat API call
     // await sendChatData(userId, userText, npcText);

      const updatedDocResponse = await fetch(`https://proto1-production.up.railway.app/api/realtime-doc/${sessionId}`);
      if (!updatedDocResponse.ok) {
        throw new Error('Error fetching updated document');
      }

      const updatedDocData = await updatedDocResponse.json();
      setDocumentText(updatedDocData.UpdatedDoc);
    } catch (error) {
      console.error('Error fetching or sending data:', error);
    }
  };

  const sendChatData = async (userId, userText, npcText) => {
    /*console.log('Sending chat data:', {
      sessionId,
      userId,
      userText,
      npcText,
      timestamp: new Date().toISOString(),
      type: 'chatMessage',
    });*/

    try {
      const updateResponse = await fetch('https://proto1-production.up.railway.app/api/chat', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          sessionId,
          userId,
          userText,
          npcText,
          timestamp: new Date().toISOString(),
          type: 'chatMessage',
        }),
      });

      const responseData = await updateResponse.json();
      //console.log('Server response:', responseData);
    } catch (error) {
     // console.error('Error sending chat data:', error);
    }
  };

  const fetchSessionDetails = async () => {
    try {
      const response = await fetch(`https://proto1-production.up.railway.app/api/session-details/${sessionId}`);
      if (!response.ok) {
        throw new Error('Error fetching session details');
      }
      const data = await response.json();
      setSessionDetails({ name: data.name, goal: data.goal });
    } catch (error) {
      console.error('Error fetching session details:', error);
    }
  };

  useEffect(() => {
    const socket = io('https://proto1-production.up.railway.app');

    const userId = localStorage.getItem('userId');
    if (!sessionId || !userId) {
      console.error('Session ID or User ID is missing');
      return;
    }

    fetchSessionDetails();

    const state = location.state;
    if (state && state.updatedDoc) {
      setDocumentText(state.updatedDoc);
    } else {
      fetchDocument();
    }

    socket.emit('join-session', { sessionId, userId });

    socket.on('connect', () => {
      //console.log('WebSocket connection established.');
    });

    socket.on('message-to-user', (messageData) => {
	//console.log('Received ping from server:', messageData);
	  // Extract the user message
	  const userMessage = messageData.message;
	  /*setMessages((prevMessages) => [
		  ...prevMessages,
		  { sender: "npc", content: userMessage.trim() }
		]);*/
	  // Prepend [SPECIAL] and send the extracted message to Convai
	  client.handleConvaiTextSend(`[SPECIAL] ${userMessage}`);
		});

    return () => {
      socket.disconnect();
    };
  }, [sessionId, location]);

  const handleNpcTextUpdate = (npcText) => {
    setNpcText(npcText);
    fetchDocument();
  };

  const handleIsTalkingChange = (isTalking) => {
    setIsTalking(isTalking);
  };

  // In SessionPage.jsx - modify only the handleUserTextUpdate function:
const handleUserTextUpdate = async (userText) => {
  setUserText(userText);
  const userId = localStorage.getItem('userId');
  if (userText.trim() !== '') {
    await sendChatData(userId, userText, npcText); //sends to groq llm
    //fetchDocument();
  }
};

  const handleFacialDataUpdate = (facialData) => {
    console.log('Facial data received:', facialData);
  };

  const { client } = useConvaiClient({
    sessionId,
    onNpcTextUpdate: handleNpcTextUpdate,
    onIsTalkingChange: handleIsTalkingChange,
    onUserTextUpdate: handleUserTextUpdate,
    onFacialDataUpdate: handleFacialDataUpdate,
  });

  const handleTextSend = (localUserText) => {
   //console.log('handleTextSend called in SessionPage');

    if (client && client.handleConvaiTextSend) {
      //console.log('Sending to ConvaiText:', localUserText);
      client.handleConvaiTextSend(localUserText);
    } else {
      console.error('Convai client or handleTextSend not available');
    }

    const userId = localStorage.getItem('userId');
    if (localUserText.trim() !== '') {
      //console.log('Sending chat data to server:', { userId, localUserText, npcText });
      sendChatData(userId, localUserText, npcText);
    } else {
      console.warn('Empty or whitespace-only message. Not sending.');
    }
  };

  return (
    <div className="session-container">
      <div className="chat-section">
        <h1 className="logo">FüZ</h1>
        <h2 className="session-title">{sessionDetails.name || 'Session'}</h2>
		<div className="document-section">
        <h3 className="session-goal">{sessionDetails.goal || 'No specific goal'}</h3>
        <div className="document-content">
          {documentText ? (
            <ReactMarkdown>{documentText}</ReactMarkdown>
          ) : (
            'Loading document...'
          )}
        </div>
      </div>
        
      </div>

      

      <div className="avatar-section">
        <ThreeJsAvatar client={client} />
		{/* ChatBubble component */}
        <ChatBubble
          messages={messages} // Pass messages state down as a prop
          setMessages={setMessages} // Pass setMessages function down as a prop
          client={{ ...client, setUserText: setUserText, handleTextSend: handleTextSend }}
        />
      </div>
    </div>
  );
};

export default SessionPage;
