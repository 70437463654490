import React, { useEffect, useRef, useState } from 'react';
import '../../index.css';

const ChatBubblev2 = (props) => {
  const {
    userText,
    npcText,
    messages,
	setMessages,
    chatHistory,
    keyPressed,
    npcName,
    userName,
    handleTextSend,
  } = props;

  const [value, setValue] = useState("");
  const containerRef = useRef(null);

  // Keep scroll at bottom
  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  });

  const handleChange = (e) => {
    e.stopPropagation();
    setValue(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (value.trim()) {
		 setMessages(prevMessages => [...(prevMessages || []), {
        sender: "user",
        content: value.trim()
      }]);
      handleTextSend(value);
      setValue("");
    }
  };

  return (
    <section
      className="container"
      style={{
        position: "absolute",
        top: "50vh",
        width: "33vw",
        height: "45vh",
        borderRadius: "10px",
        background: "rgba(0, 0, 0, 0.7)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Text Box at the top */}
      <div
        className="container-textBox"
        style={{
          width: "100%",
          padding: "10px 20px",
          fontSize: "16px", // Reduced font size
          background: "#f5f5f5", // Close to white background color
          boxSizing: "border-box",
        }}
      >
        <div
          className="textBox"
          style={{
            color: "black",
            width: "100%",
            fontSize: "16px", // Adjusted font size
          }}
        >
          {keyPressed ? (
            <div className="icon">
              <span className="span-prop" />
              <span className="span-prop" />
              <span className="span-prop" />
            </div>
          ) : (
            <form onSubmit={handleSubmit}>
              <input
                className="placeholder1"
                style={{
                  backgroundColor: "transparent",
                  borderWidth: "0px",
                  width: "100%",
                  color: "black",
                  fontSize: "16px", // Adjusted font size
                }}
                onChange={handleChange}
                value={value}
                placeholder="Type your response here and press enter"
                type="text"
              />
            </form>
          )}
        </div>
      </div>

      {/* Chat log below text box */}
      <div
        className="container-chat1"
        ref={containerRef}
        style={{
          width: "100%",
          flex: 1, // Allow chat to take the remaining space
          overflowY: "auto", // Enable scrolling
          marginBottom: "25px",
          marginTop: "15px",
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {messages?.map((message, idx) => (
          <section key={idx}>
            {message.sender === "user" && (
              <div style={{ display: "flex", marginBottom: "-12px" }}>
                <p
                  style={{
                    color: "rgba(243,167,158,255)",
                    paddingLeft: "20px",
                    marginRight: "-10px",
                    fontWeight: "bold",
                  }}
                >
                  {userName || "You"}:
                </p>
                <p style={{ color: "#FFFFFF", paddingLeft: "15px" }}>
                  {message.content}
                </p>
              </div>
            )}
            {message.sender === "npc" && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginLeft: "20px",
                }}
              >
                <div>
                  <span
                    style={{
                      color: "rgba(127,210,118,255)",
                      marginRight: "-10px",
                      fontWeight: "bold",
                    }}
                  >
                    {"Moderator"}:
                  </span>
                  <span style={{ color: "#FFFFFF", paddingLeft: "15px" }}>
                    {message.content}
                  </span>
                </div>
              </div>
            )}
          </section>
        ))}
      </div>
    </section>
  );
};

export default ChatBubblev2;
