import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css';

const LandingPage = () => {
  const navigate = useNavigate();
  const [sessions, setSessions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newSessionData, setNewSessionData] = useState({
    name: '',
    goal: '',
    duration: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewSessionData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };

  const startNewSession = async () => {
    if (!newSessionData.name || !newSessionData.goal || !newSessionData.duration) {
      alert('All fields are required.');
      return;
    }

    try {
      const response = await fetch('https://proto1-production.up.railway.app/api/new-session', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newSessionData),
      });

      if (!response.ok) {
        throw new Error('Failed to create a new session');
      }

      const sessionData = await response.json();
      const { sessionId, userId, name, goal } = sessionData;

      //console.log(`New session created with ID: ${sessionId}, and userId: ${userId}`);

      navigate(`/session/${sessionId}`, { state: { name, goal } });
    } catch (error) {
      console.error('Error starting new session:', error);
    }
  };

  const joinSession = async (sessionId) => {
    try {
      const response = await fetch('https://proto1-production.up.railway.app/api/join-session', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ sessionId }),
      });

      if (!response.ok) {
        throw new Error('Failed to join session');
      }

      const { userId, name, goal, updatedDoc } = await response.json();
		localStorage.setItem('userId', userId);
		navigate(`/session/${sessionId}`, { state: { name, goal, updatedDoc } });

    } catch (error) {
      console.error('Error joining session:', error);
    }
  };

  useEffect(() => {
    const fetchSessions = async () => {
      try {
        const response = await fetch('https://proto1-production.up.railway.app/api/active-sessions');
        if (!response.ok) {
          throw new Error('Failed to fetch sessions');
        }
        const data = await response.json();
        setSessions(data.activeSessions);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching sessions:', error);
        setLoading(false);
      }
    };

    fetchSessions();
  }, []);

  return (
    <div className="landing-page">
      <header className="header">
        <h1 className="logo">FüZ</h1>
      </header>
      
      <div className="hero">
        <img src="./images/landing.jpg" alt="Landing" className="hero-image" />
        <h2 className="hero-title">The Consensus Gathering Tool</h2>
      </div>
      
      <div className="new-session-form">
        <input
          type="text"
          name="name"
          placeholder="Session Name"
          value={newSessionData.name}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="goal"
          placeholder="Session Goal"
          value={newSessionData.goal}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="duration"
          placeholder="Duration (e.g., 30 minutes)"
          value={newSessionData.duration}
          onChange={handleInputChange}
        />
        <button onClick={startNewSession}>Start New Session</button>
      </div>

      {loading ? (
        <p>Loading active sessions...</p>
      ) : (
        <div className="session-grid">
          {sessions.length > 0 ? (
            sessions.map((session) => (
              <div key={session.id} className="session-tile">
                <h3>{session.name}</h3>
                <p><strong>Goal:</strong> {session.goal}</p>
                <p><strong>Duration:</strong> {session.duration}</p>
                <p><strong>Started at:</strong> {new Date(session.startTime).toLocaleTimeString()}</p>
                <button onClick={() => joinSession(session.id)}>Join</button>
              </div>
            ))
          ) : (
            <p>No active sessions</p>
          )}
        </div>
      )}
    </div>
  );
};

export default LandingPage;