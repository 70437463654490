import React, { useEffect, useRef, useState } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';
import { useLipsync } from './hooks/useLipsync';
import { useHeadTracking } from './hooks/useHeadTracking';

export function Nikhil(props) {
  const { nodes, materials, scene } = useGLTF('/nikhil.glb');
  const { animations } = useGLTF('/animations.glb');
  const nikhilRef = useRef();
  const { actions, mixer } = useAnimations(animations, nikhilRef);
  const [animation, setAnimation] = useState(
    animations.find((a) => a.name === 'Idle') ? 'Idle' : animations[0].name // Check if Idle animation exists otherwise use first animation
  );
  const { client } = props;

  useEffect(() => {
    client?.convaiClient.current.sendTextChunk("");
  }, []);

  useEffect(() => {
    if (client?.isTalking) {
      setAnimation('Idle');
    } else {
      setAnimation('Idle');
    }
  }, [client?.isTalking]);

  useEffect(() => {
    actions[animation]
      .reset()
      .fadeIn(mixer.stats.actions.inUse === 0 ? 0 : 0.5)
      .play();
    return () => actions[animation].fadeOut(0.5);
  }, [animation]);

  // Extract and reapply material on load
useEffect(() => {
  if (nikhilRef.current) {
   // console.log("Nikhil ref loaded, starting material reapplication.");

    // Clone the material to create a fresh reference
    const materialClone = materials.Character.clone();
  //  console.log("Material cloned:", materialClone);

    nikhilRef.current.traverse((child) => {
      if (child.isMesh && child.material) {
      //  console.log("Applying cloned material to:", child.name);
        child.material = materialClone; // Reapply the cloned material
        child.material.needsUpdate = true; // Force update to ensure it's applied correctly
      //  console.log("Material applied and needsUpdate set to true for:", child.name);
      } else if (child.isMesh) {
       // console.log("Mesh found without material:", child.name);
      }
    });
  } else {
    console.log("Nikhil ref not yet loaded.");
  }
}, [materials.Character]);


  useLipsync({ client, characterRef: nikhilRef, nodes, scene });
  useHeadTracking({ client, nodes });

  return (
    <group ref={nikhilRef} {...props} dispose={null}>
      <group name="Scene">
        <group name="party-m-0001" scale={0.01}>
          <skinnedMesh
            name="Cesar_D_LaTorre_Jacket"
            geometry={nodes.Cesar_D_LaTorre_Jacket.geometry}
            material={materials.Character} // Reapplied material here
            skeleton={nodes.Cesar_D_LaTorre_Jacket.skeleton}
            morphTargetDictionary={nodes.Cesar_D_LaTorre_Jacket.morphTargetDictionary}
            morphTargetInfluences={nodes.Cesar_D_LaTorre_Jacket.morphTargetInfluences}
          />
          <primitive object={nodes.RL_BoneRoot} />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload('/nikhil.glb');
