import axios from "axios";
import { ConvaiClient } from "convai-web-sdk";
import { useEffect, useRef, useState } from "react";

export function useConvaiClient({ sessionId, onNpcTextUpdate, onIsTalkingChange, onUserTextUpdate }) {
  const apiKey = process.env.REACT_APP_CONVAI_API_KEY;
  const characterId = process.env.REACT_APP_CONVAI_CHARACTER_ID;
  
  const [userText, setUserText] = useState("");
  const [npcText, setNpcText] = useState("");
  const [isTalking, setIsTalking] = useState(false);
  const [audioPlay, setAudioPlay] = useState(false);
  const [avatar, setAvatar] = useState("");
  const [npcName, setNpcName] = useState("Npc");
  const [userName, setUserName] = useState("User");
  const [facialData, setFacialData] = useState([]);
  const [emotionData, setEmotionData] = useState([]);
  const [gender, setGender] = useState("MALE");
  const [userEndOfResponse, setUserEndOfResponse] = useState(false);
  const npcTextRef = useRef();
  const convaiClient = useRef(null);
  const facialRef = useRef([]);
  const finalizedUserText = useRef();

  useEffect(() => {
    // Initialize Convai Client
	if (!convaiClient.current) {
   // console.log("Initializing Convai Client...",convaiClient.current);
    convaiClient.current = new ConvaiClient({
      apiKey: apiKey,
      characterId: characterId,
      enableAudio: true,
      faceModel: 3,
      enableFacialData: true,
    });
	//console.log("Initializing Convai Client...",convaiClient.current);
	
	}else
	//console.log('tried to initialize two convai clients',convaiClient.current);
	

	

    convaiClient.current.setErrorCallback((type, message) => {
      console.log(type, message);
    });

    convaiClient.current.setResponseCallback((response) => {
		//console.log("Response received from Convai.");

      if (response.hasUserQuery()) {
        let transcript = response.getUserQuery();
        if (transcript.getIsFinal()) {
          finalizedUserText.current += " " + transcript.getTextData();
          transcript = "";
        }
        setUserText(finalizedUserText.current + (transcript?.getTextData() || ""));
      }
      if (response.hasAudioResponse()) {
        if (!response.getAudioResponse().getEndOfResponse()) {
          const audioResponse = response.getAudioResponse();
          if (audioResponse.getVisemesData()?.array[0]) {
            const faceData = audioResponse.getVisemesData().array[0];
            if (faceData[0] !== -2) {
              facialRef.current.push(faceData);
              setFacialData(facialRef.current);
            }
          }
          npcTextRef.current += " " + audioResponse.getTextData();
          setNpcText(npcTextRef.current);
          setIsTalking(true);
        }
        if (response.getAudioResponse().getEndOfResponse()) {
          setUserEndOfResponse(true);
        }
      }
    });
	
	//console.log("Attempting to send text chunk...");
//convaiClient.current.sendTextChunk("Test message");



    // Fetch NPC details (avatar, name, etc.)
    const fetchNpcData = async () => {
      try {
        const url = "https://api.convai.com/character/get";
        const payload = { charID: process.env.REACT_APP_CONVAI_CHARACTER_ID };
        const headers = {
          "CONVAI-API-KEY": process.env.REACT_APP_CONVAI_API_KEY,
          "Content-Type": "application/json",
        };

        const response = await axios.post(url, payload, { headers });

        // Update NPC data
        setAvatar(response.data.model_details.modelLink);
        setNpcName(response.data.character_name);
        setGender(response.data.voice_type);
		//console.log('NPC is setup - voice type:', response.data.voice_type);
      } catch (error) {
        console.error('Error fetching character data:', error);
      }
    };

    fetchNpcData();

    convaiClient.current.onAudioPlay(() => setAudioPlay(true));
    convaiClient.current.onAudioStop(() => {
      setAudioPlay(false);
      facialRef.current = [];
      setFacialData([]);
	  setIsTalking(false);
    });
  }, []);
  
  
let lastSentText = ''; // Variable to track the last sent message
  // Send text input to Convai
  
  //Sends user's message to the convai client
  function sendText(tempText) {
    if (convaiClient.current) {
      finalizedUserText.current = "";
      npcTextRef.current = "";
      setNpcText("");
      convaiClient.current.sendTextChunk(tempText);
	 // console.log('Sent to Convai:', tempText);
      //setEnter(0);
    }
  }
  
const handleConvaiTextSend = (userText) => {
  if (!userText || userText.trim() === '') {
    console.warn('Message is empty or contains only whitespace.');
    return;
  }

  if (userText === lastSentText) {
    //console.warn('Message is the same as the last one. Not sending duplicate.');
    return;
  }

  finalizedUserText.current = '';
  npcTextRef.current = '';

  if (convaiClient.current) {
    sendText(userText);
    lastSentText = userText;
    //console.log('Sent to Convai:', userText);
    // Only call onUserTextUpdate if it's not a special message
    if (!userText.startsWith('[SPECIAL]')) {
		
		//console.log('NotSpecial-OnUserText:', userText);
      onUserTextUpdate(userText);//goes to groq llm also
    }
  } else {
    console.error('ConvaiClient or sendTextChunk is not available');
  }
};

  return {
    client: {
      convaiClient,
      npcText,
      userText,
      characterId,
      handleConvaiTextSend,
      npcName,
      userName,
      gender,
      avatar,
      isTalking,
      facialData,
      emotionData,
      userEndOfResponse,
      setUserText,
      setNpcText,
      setIsTalking,
      setFacialData,
      setEmotionData,
    },
  };
}
