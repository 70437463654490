import React from "react";
import { useState, useEffect } from "react";
import ChatBubblev2 from "./ChatBubbleV2";
import "../../index.css";

const ChatBubble = (props) => {
  const { messages, setMessages, client, ...otherProps } = props;
  const [errorResponse, setErrorResponse] = useState(false);
  const [session, setSession] = useState("-1");
  const errorMessage = "Error in retrieving response. Please reset session.";

  // Function to generate storage key using both characterId and sessionId
  const getStorageKey = (characterId, sessionId) => {
    return `messages_${characterId}_${sessionId}`;
  };

  // Function to handle user input
  const userInput = (text) => {
    if (client?.handleTextSend) {
      client.handleTextSend(text);
    }
  };

  // Load saved messages on component mount or character/session change
  useEffect(() => {
    if (client?.characterId && session !== "-1") {
      const storageKey = getStorageKey(client.characterId, session);
      const storedData = localStorage.getItem(storageKey);
      
      if (storedData) {
        try {
          const parsedMessages = JSON.parse(storedData);
          setMessages(parsedMessages);
        } catch (error) {
          console.error('Error parsing stored messages:', error);
          setMessages([]);
        }
      } else {
        // New session, start with empty messages
        setMessages([]);
      }
    }
  }, [client?.characterId, session]);

  // Save messages to localStorage when they change
  useEffect(() => {
    if (client?.characterId && session !== "-1" && messages.length) {
      const storageKey = getStorageKey(client.characterId, session);
      try {
        localStorage.setItem(storageKey, JSON.stringify(messages));
      } catch (error) {
        console.error('Error saving messages to localStorage:', error);
      }
    }
  }, [messages, client?.characterId, session]);

  // Update session ID
  useEffect(() => {
    if (
      client?.convaiClient?.current &&
      session === "-1" &&
      client?.convaiClient?.current?.sessionId
    ) {
      setSession(client.convaiClient.current.sessionId);
    }
  }, [client?.convaiClient?.current, session]);

  // Handle NPC messages
  useEffect(() => {
    if (errorResponse && !client?.npcText) {
      const newMessage = {
        sender: "npc",
        content: errorMessage,
      };
      setMessages(prevMessages => [...prevMessages, newMessage]);
      setErrorResponse(false);
    } else if (client?.npcText && !client?.isTalking) {
      // Only add NPC message if it's different from the last message
      setMessages(prevMessages => {
        const lastMessage = prevMessages[prevMessages.length - 1];
        if (!lastMessage || lastMessage.content !== client.npcText) {
          return [...(prevMessages || []), {
            sender: "npc",
            content: client.npcText,
          }];
        }
        return prevMessages;
      });
    }
  }, [client?.isTalking, client?.npcText, errorResponse]);

  // Cleanup old messages on unmount
  useEffect(() => {
    return () => {
      // Optional: Clean up old messages for this session
      if (client?.characterId && session !== "-1") {
        const storageKey = getStorageKey(client.characterId, session);
        localStorage.removeItem(storageKey);
      }
    };
  }, [client?.characterId, session]);

  return (
    <section className="ChatBubble">
      <ChatBubblev2
        userText={client?.userText}
        npcText={client?.npcText}
        messages={messages}
        setMessages={setMessages}
        userInput={userInput}
        handleTextSend={client?.handleTextSend}
        keyPressed={client?.keyPressed}
        npcName={client?.npcName}
        userName={client?.userName}
      />
    </section>
  );
};

export default ChatBubble;