import React, { Suspense, useRef, useEffect } from 'react'
import { Canvas, useThree } from '@react-three/fiber'
import { Sky } from '@react-three/drei'
import { Physics } from '@react-three/rapier'
import { Nikhil } from './Nikhil'

function CameraSetup() {
  const { camera } = useThree()
  
  useEffect(() => {
    camera.position.set(0, 0.84, 2) // Lowered y-value from 1.2 to 0.84 (30% lower)
    camera.lookAt(0, 1.6, 0) // Kept the look-at point the same
    camera.fov = 20
    camera.updateProjectionMatrix()
  }, [camera])
  
  return null
}

export default function ThreeJsAvatar({ client }) {
  return (
    <div style={{ width: '70%', height: '50%' }}>
      <Canvas
        shadows
        camera={{
          position: [0, 0.84, 2], // Initial camera position, 
          fov: 40,
        }}
      >
        <CameraSetup />
        <ambientLight intensity={0.2} />
        <hemisphereLight
          skyColor={'#fcf9d9'}
          groundColor={'#fcf9d9'}
          intensity={0.5}
        />
        <directionalLight
          position={[500, 100, 500]}
          color={'#fcf9d9'}
          intensity={2}
          castShadow
        />
        <Suspense fallback={null}>
          <Physics>
            <Nikhil client={client} />
            <Sky />
          </Physics>
        </Suspense>
      </Canvas>
    </div>
  )
}